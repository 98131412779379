(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// legacy browser alert
function isLegacyIE() {
  var ua = navigator.userAgent;

  if (ua.indexOf("MSIE 6.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 7.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 8.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 9.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 10.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else {
    return false;
  }
}

isLegacyIE();
$(function () {
  // ボタンをクリックしたら発動
  $('.ib').click(function () {
    // もしチェックが入っていたら
    if ($('input[name="menu-navibtn"]').prop('checked')) {
      // チェックを外す
      $('input[name="menu-navibtn"]').prop('checked', false); // もしチェックが外れていたら
    } else {
      // チェックを入れる
      $('input[name="menu-navibtn"]').prop('checked', true);
    }
  });
}); //TOP MV Swiper

setTimeout(function () {
  var MvSlider = new Swiper('.top-slider', {
    loop: true,
    speed: 800,
    slidesPerView: 1.2,
    centeredSlides: true,
    spaceBetween: 20,
    on: {
      autoplayStart: function autoplayStart() {
        setTimeout(function () {
          var sampleElement = document.getElementById('o-mainvisual-catch');
          sampleElement.classList.add('mv-text-effect');
        }, 500);
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      767: {
        spaceBetween: 80
      }
    }
  });
}, 1000); //園TOP　施設紹介 Swiper

setTimeout(function () {
  var MvSlider = new Swiper('.shisetsu-slider', {
    loop: true,
    speed: 800,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      767: {
        spaceBetween: 80,
        slidesPerView: 2
      }
    }
  });
}, 1000); //園TOP　スタッフ Swiper

setTimeout(function () {
  var MvSlider = new Swiper('.staff-slider', {
    loop: true,
    speed: 800,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      767: {
        spaceBetween: 80,
        slidesPerView: 3
      }
    }
  });
}, 1000); //園TOP　イベントカレンダー Swiper

$(function () {
  //swiper 768以下で起動
  var swiper;
  $(window).on('load resize', function () {
    var w = $(window).width();

    if (w <= 768) {
      if (swiper) {
        return;
      } else {
        swiper = new Swiper('.caleder-slider', {
          loop: true,
          spaceBetween: 20,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        });
      }
    } else {
      if (swiper) {
        swiper.destroy();
        swiper = undefined;
      }
    }
  });
}); //ページ内リンクスムーススクロール

$(function () {
  var headerHight = 200; //ヘッダの高さ
  // #で始まるアンカーをクリックした場合に処理

  $('a[href^="#"]').click(function () {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得

    var href = $(this).attr("href"); // 移動先を取得

    var target = $(href == "#" || href == "" ? "html" : href); // 移動先を数値で取得

    var position = target.offset().top - headerHight; //ヘッダの高さ分位置をずらす
    // スムーススクロール

    $("body,html").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
}); //下から表示させる要素を指定

var $pagetop = $('.__totop');
$(window).on('scroll', function () {
  //スクロール位置を取得
  if ($(this).scrollTop() < 100) {
    $pagetop.removeClass('active');
  } else {
    $pagetop.addClass('active');
  }
}); //アコーディオン

$(function () {
  $(".accordionbox dt").on("click", function () {
    $(this).next().slideToggle(); // activeが存在する場合

    if ($(this).children(".accordion_icon").hasClass("active")) {
      // activeを削除
      $(this).children(".accordion_icon").removeClass("active");
    } else {
      // activeを追加
      $(this).children(".accordion_icon").addClass("active");
    }
  });
});
$(function () {
  $(window).on('load scroll', function () {
    var scrollPos = $(this).scrollTop();

    if (scrollPos > 100) {
      $('header').addClass('is-animation');
    } else {
      $('header').removeClass('is-animation');
    }
  });
}); //アニメーション

var fadeInTarget = document.querySelectorAll('.fade-in');
window.addEventListener('scroll', function () {
  for (var i = 0; i < fadeInTarget.length; i++) {
    var rect = fadeInTarget[i].getBoundingClientRect().top;
    var scroll = window.pageYOffset || document.documentElement.scrollTop;
    var offset = rect + scroll;
    var windowHeight = window.innerHeight; // 現在のブラウザの高さ

    if (scroll > offset - windowHeight + 150) {
      fadeInTarget[i].classList.add('scroll-in');
    }
  }
});
var menu = document.querySelectorAll(".__megamenu");

function toggle() {
  var content = this.nextElementSibling;
  this.classList.toggle("is-active");
  content.classList.toggle("is-open");
}

for (var i = 0; i < menu.length; i++) {
  menu[i].addEventListener("click", toggle);
}

},{}]},{},[1]);
